import * as React from "react";
import { HeadFC } from "gatsby";
import Layout from "../components/Layout";
import Slab from "../components/Slab";
import Button from "../components/Button";

const NotFoundPage = () => {
  return (
    <Layout>
      <Slab>
        <div className="my-8">
          <aside className="md:my-12 py-8 md:py-24 px-6 w-full bg-grey-stone">
            <h1 className="text-white text-center">Page not found</h1>
            <p className="text-white text-header-2 text-center my-4">
              The page you are looking for does not exist.
            </p>
            <div className="text-center my-4">
              <Button to="/" wide dark>
                Return to the home page
              </Button>
            </div>
          </aside>
        </div>
      </Slab>
    </Layout>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
