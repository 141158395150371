import * as React from "react";
import logo from "../../../images/gilead-managed-markets@2x.png";

const Footer = () => {
  const copyright = "©2024 Gilead Sciences, Inc. All rights reserved.";
  const partcode = "US-UNBM-0141";
  const dofu = "01/24";
  return (
    <div className="container mx-auto px-4">
      <footer>
        <div className="mb-12">
          <img src={logo} className="mt-1" width="367" />
        </div>
        <div className="flex flex-col lg:flex-row lg:items-end justify-between text-reference">
          <div className="mt-5 w-full lg:w-1/2 text-left">
            <p>
              GILEAD and the GILEAD Logo are trademarks of Gilead Sciences, Inc.
              or its related companies. <br />
              All other marks referenced herein are the property of their
              respective owners.
            </p>
          </div>
          <div className="mt-5 w-full lg:w-1/2 lg:text-right">
            <p>
              {copyright} {partcode} {dofu}
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
