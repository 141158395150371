import * as React from "react";
import Close from "../../images/close.svg";
import Button from "../Button";
import "./styles.scss";

type ExitingSiteModalProps = {
  destination: string;
  isOpen: boolean;
  close: VoidFunction;
};
const ExitingSiteModal: React.FunctionComponent<ExitingSiteModalProps> = ({
  destination,
  isOpen,
  close,
}) => {
  const [isOpening, setIsOpening] = React.useState(false);
  const classList = [
    "modal",
    isOpen ? "modal--open" : "modal--hidden",
    isOpening && "modal--opening",
  ];

  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsOpening(true);
      }, 250);
    } else {
      setIsOpening(false);
    }
  }, [isOpen]);

  const onClose = () => {
    setIsOpening(false);
    setTimeout(() => {
      close();
    }, 250);
  };
  const onExit = () => {
    close();
  };

  const destinationMessage = message(destination);
  return (
    <div className={classList.filter((x) => x).join(" ")}>
      <div className="modal__background fixed top-0 left-0 right-0 bottom-0 bg-grey-stone-70 ">
        <div className="container mx-auto flex justify-center items-center h-[100vh]">
          <div className="modal__foreground lg:w-3/5 xl:w-1/2 bg-white p-4 rounded">
            <div className="close text-right text-black">
              <button onClick={onClose}>
                <img src={Close} />
              </button>
            </div>
            <div className="mt-5 mx-12">
              <p className="text-body text-left text-black">
                By following this link you are leaving this Gilead property.{" "}
                {destinationMessage} Gilead provides this link as a convenience.
              </p>
              <div className="flex flex-col md:flex-row w-full justify-center my-5">
                <div className="m-5">
                  <Button onClick={onClose} wide>
                    Go Back
                  </Button>
                </div>
                <div className="m-5">
                  <Button to={destination} onClick={onExit} wide>
                    Leave Site
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function message(destination: string): string {
    if (destination.match(/aidsvu\.org/)) {
      return "AIDSVu is presented by the Rollins School of Public Health at Emory University in partnership with Gilead.";
    }
    return "";
  }
};

export default ExitingSiteModal;
