import * as React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Disclaimer from "./Disclaimer";

const Layout: ComponentWithChildren = ({ children }) => {
  return (
    <div>
      <Disclaimer />
      <div className="container mx-auto sm:px-4 mb-11">
        <Header />
      </div>
      <main>{children}</main>
      <div className="bg-grey-light pt-12 pb-11">
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
