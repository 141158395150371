import * as React from "react";
const Disclaimer = () => {
	return (
		<div className="bg-grey-stone-15">
			<p className="text-red text-body font-bold text-center py-3 md:py-7 px-12">
				This site is intended for US access decision makers.{" "}
			</p>
		</div>
	);
};

export default Disclaimer;
